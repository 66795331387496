@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
:root {
  /* 
  --color-bg: #1f1f38;
  --color-bg-variant: #2c2c5c;
  --color-bg-variant-trans: rgba(44, 44, 92, 0.85);
  --color-primary: #4db5ff;
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6); */


  --color-bg: #fff;
  --color-bg-variant: #79c7ff;
  --color-bg-variant-trans: rgba(255, 255, 255, 0.6);
  --color-primary: #4db5ff;
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-white: #1f1f38;
  --color-light: rgba(44, 44, 92, 0.85);

  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;

  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}
html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  display: none;
}
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
}
@font-face {
  font-family: 'Varela Round', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
}
@font-face {
  font-family: 'Cairo', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap');
}
body {
  font-family: 'Cairo', sans-serif;
  background: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
  /* background-image: url(../src/bg-texture.png); */
  background-image: linear-gradient(
    90deg,
    rgb(77 181 255 / 4%) 50%,
    transparent 50%
  ),
  linear-gradient(90deg, rgb(159 252 254 / 50%) 50%, transparent 50%);
  background-size: 10px, 0, 30px, 50px;
}
/* ======================= GENERAL STYLES ======================= */
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}
h1 {
  font-size: 2.5rem;
}
section {
  margin-top: 8rem;
  /* height: 100vh; */
}
section > h2,
section > h5 {
  text-align: center;
  color: var(--color-light);
}

section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}
.text-light {
  color: var(--color-light);
}
a {
  color: var(--color-primary);
  transition: var(--transition);
}
a:hover {
  color: var(--color-white);
}
.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}
.btn:hover {
  background: var(--color-primary-varian);
  color: var(--color-primary);
  border-color: transparent;
}
.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}
.btn-secondary {
  background: var(--color-light);
}
img {
  display: block;
  width: 100%;
  object-fit: cover;
}
.scroll__settings{
  position: fixed;
  bottom: 6rem;
  right: 2rem;
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 999;
}
/* .scroll__settings[style*="direction:rtl"] {
  right: 2rem;
}
.scroll__settings[style*="direction:ltr"] {
  left: 2rem;
} */
.scroll__top-btn, .translate__button{
  font-size: 1rem;
  background: var(--color-bg);
  color: var(--color-primary);
  border-radius: 0.5rem;
  padding: 0.5rem;
  transition: var(--transition);
  cursor: pointer;
  border: 1px solid  var(--color-primary-variant);
}
.scroll__top-btn:hover, .translate__button:hover{
  background: var(--color-primary);
  color: var(--color-bg);
  border-color: transparent;
  box-shadow: 0px 0px 5px 1px var(--color-bg);
}
.translate-wrapper{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
}
/* ======================= MEDIA QUERIES (MEDIUM DEVICES) _tablets_ ======================= */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
  section {
    margin-top: 6rem;
  }
}
/* ======================= MEDIA QUERIES (SMALL DEVICES) _mobile_ ======================= */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }
  section > h2 {
    margin-bottom: 2rem;
  }
  .scroll__settings {
    right: 1.5rem;
  }
  /* .scroll__settings:dir(rtl) {
    right: 1.5rem;
  }
  .scroll__settings:dir(ltr) {
    left: 1.5rem;
  } */
}
@media print {
  .noprint {
    visibility: hidden;
  }
}
.index_wrapper{
  margin-bottom: 7rem;
}