.not-found {
  width: 560px;
  height: 225px;
  margin-right: -10px;
}
.starry-sky {
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
.search-icon {
  display: inline-block;
}
.notfound-bg {
  position: relative;
  background: var(--color-white);
}
.notfound-copy {
  color: #fff;
  position: absolute;
  top: 25px;
  right: 10%;
  text-align: right;
}
h1 {
  font-weight: 700;
  font-size: 40px;
}
/* change to alternating star opacities */
.all-stars {
  animation: blinkblink 7s linear infinite;
}

@keyframes blinkblink {
  50% {
    opacity: 0.1;
  }
}
@media (max-width: 647px) {
  .moon {
    padding-top: -500px;
  }
}
