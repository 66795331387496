.container.contact__container {
  width: 58%;
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 12%;
}
.contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.contact__option {
  background: var(--color-primary-variant);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
}
.contact__option:hover {
  background: transparent;
  border-color: var(--color-bg-variant);
}
.contact__option:hover a{
  color: var(--color-primary);
}
.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
  color: var(--color-bg);
}
/* ======================= FORM ======================= */
form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-white);
}
.loader-btn {
  cursor: default !important;
  padding: 0.75rem 2rem !important;
}
.loader {
  border: 0.3rem solid var(--color-bg); /* Light grey */
  border-top: 0.3rem solid var(--color-white); /* Blue */
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* ======================= MEDIA QUERIES (MEDIUM DEVICES) _tablets_ ======================= */
@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
/* ======================= MEDIA QUERIES (SMALL DEVICES) _mobile_ ======================= */
@media screen and (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-sm);
  }
}
