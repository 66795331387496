header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}
.wiffy{
  line-height: 1.4rem;
  text-align: center;

}
.wiffy::after{
  content: "";
  display: inline-block;
  width: 40%;
  height: 5px;
  background-color: rgb(77 181 255 / 35%);
  position: absolute;
  z-index: -1;
  right: 17rem;
  top: 6.6rem;
}
.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}
/* ======================= CTA ======================= */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}
/* ======================= HEADER SOCIALS ======================= */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}
.header__socials:after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}
/* ======================= HEADER BAR == ===================== */
.header__bar {
  /* background: var(--color-bg-variant-trans); */
  /* background: rgba(31, 31, 56, 0.75); */
  width: 100%;
  padding: 0.5rem 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
}
.header__bar .header__logo {
  width: 50px;
  height: 50px;
  background: var(--color-primary);
  border-radius: 0.5rem;
  box-shadow: 0 0 16px var(--color-bg-variant);
}
.header__logo  img {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}
.settings__wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.translate__button {
  /* padding: 0.5rem 0.8rem; */
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: var(--color-primary-variant); */
}
 /* .translate__button:hover {
  background: var(--color-primary-variant);
  color: var(--color-white);
}  */
.translate__button-active{
  cursor: default!important;
  background: var(--color-primary)!important;
  color: var(--color-bg)!important;
  border-color: transparent!important;
}
.translate__button small {
  margin: 0 0.5rem;
}

.site__mode {
  cursor: pointer;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.moon-logo:hover,
.sun-logo:hover {
  color: var(--color-primary);
}
.moon-logo {
  transform: translateY(0) rotateZ(0deg);
  transition: all 1s ease-out;
}
.sun-logo {
  transform: translateY(5%) rotateZ(50deg);
  transition: all 1s ease-out;
}
.animate-sun {
  transform: translateY(0) rotateZ(0deg);
}
.animate-moon {
  transform: translateY(20%) rotateZ(50deg);
}
/* ======================= ME ======================= */
.me {
  background: linear-gradient(var(--color-primary-variant), transparent);
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem;
}
/* ======================= SCROLL DOWN ======================= */
.scroll__down {
  position: absolute;
  right: -1.5rem;
  bottom: 5rem;
  display: grid;
  row-gap: 2.25rem;
  justify-items: center;
}
.scroll__down span {
  font-size: 0.9rem;
  font-weight: 300;
  transform: rotate(90deg);
}
.scroll__down_mouse {
  transform: rotate(180deg);
  font-size: 1.5rem;
}

/* ======================= MEDIA QUERIES (MEDIUM DEVICES) _tablets_ ======================= */
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}
/* ======================= MEDIA QUERIES (SMALL DEVICES) _mobile_ ======================= */
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
  /* .header__socials,
  .scroll__down {
    display: none;
  } */
  .scroll__down {
    display: none;
  }
  .header__bar {
    padding: 1rem;
  }
  /* .header__bar img {
    width: 140px;
    height: 40px;
  } */
  .me {
    width: 20rem;
    height: 28rem;
    left: calc(50% - 10rem);
  }
  .wiffy::after{
    width: 70%;
    height: 5px;
    z-index: -1;
    right: 1rem;
    top: 8rem;
  }
}
