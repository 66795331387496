/* ======================= SERVICE MODAL ======================= */
.modal {
  position: fixed;
  inset: 0;
  background: var(--color-bg-variant-trans);
  padding: 2rem 1rem;
  display: grid;
  place-items: center;
  transition: var(--transition);
  z-index: var(--z-modal);
}
.modal-visibility {
  visibility: hidden;
  opacity: 0;
}
.modal-content {
  position: relative;
  background: var(--color-bg);
  padding: 4.5rem 1.5rem 2.5rem;
  border-radius: 1.5rem;
  width: 65%;
  border: 2px solid var(--color-primary);
}
.modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  color: var(--color-primary-variant);
  cursor: pointer;
}
/* ======================= MEDIA QUERIES (SMALL DEVICES) _mobile_ ======================= */
@media screen and (max-width: 600px) {
  .modal-content {
    width: 100%;
  }
}
