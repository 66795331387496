/* ____________________________ RESPONSIVNESS ____________________________  */
.business_wrapper{
    width: 100vw;
    height: 100vh;
    margin: 0;
    overflow: hidden;
}
.business_wrapper .container{
    width: 100%;
    height: 100%;
    /* background: #4dbbff; */
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}
.business_wrapper  .content_wrapper{
    height: 98%;
    width: 500px;
    border-radius: 1.2rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    padding: 1rem;
    box-shadow: inset 0px 0px 10px 10px #e1e1e1;
    position: relative;
    background-color:#fff;
    background-image: url(../../assets/Images/businessCover.jpg);
    background-repeat: no-repeat;
    background-size: cover;

}


.business_wrapper  .inner_wrapper{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 1.2rem;
    padding: 2rem;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
    box-shadow: 1px 1px 8px #b4b4b4;
    justify-content: flex-start!important;
}
.business_wrapper  .content_wrapper .overlay {
    background: linear-gradient(to top, rgb(255 255 255) 40%, rgb(255 255 255 / 44%) 100%);
    position: absolute;
     bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}
.business_wrapper  .inner_wrapper::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  .center_col_flex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .actions_row{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .white_shadow{
    background: #fff;
    border-radius: 0.5rem;
    padding: 0.5rem;
    box-shadow: 0px 0px 7px 1px #00000030;
    margin-top: 1.5rem;
  }
  .infoCard{
    position: relative;
    margin-top: 3rem;
    padding-top: 6rem;
    width: 100%;
  }
  .avatarWrapper{
    background-color: #fff;
    background-image: url(../../assets/ArkanPic.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 130px;
    height: 130px;
    position: absolute;
    top: -3rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    border-radius: 50%;
    box-shadow: 0px 0px 7px 1px #00000030;
  }
  .infoQuote {
    text-align: center;
    font-weight: 600;
    margin: 0.2rem;
    line-height: 1.2;
  }
  .card_CTA{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 1rem 0;
  }
  .card_CTA a{
    border-radius: 0.5rem;
    background: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card_CTA .lgIco {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    font-size: 1.5rem;
  }
  .card_CTA .smIco {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.2rem;
  }
  .card_CTA :is(.lgIco, .smIco){
    color: #ffffff!important;
    /* height: 1.5rem; */
}
.card_CTA :is(.lgIco, .smIco) a > *{    
    width: 90%;
    height: 90%;
}
.btn_Action{
  padding: 1rem;
  /* height: 50px; */
  width: 23%;
  cursor: pointer;
}
.btn_Action button{
  all:unset;
}
.btn_Action img{
  width: 2rem;
  height: 2rem;
  object-fit: cover;
}
.web_button{
  padding: 1rem;
  /* height: 50px; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--color-bg-variant);
  color: #ffffff;
  font-weight: 600;
}
.web_button span{
  margin: 0 1rem;
}
.images_container_viewer{
  background: #e8e8e8;
  width: 100%;
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
}
.images_container_viewer img{
  width: 6rem;
  height: 6rem;
  border-radius: 5px;
  object-fit: cover;
  cursor: pointer;
  filter: grayscale(0.8);
}
.lightBox_imgViewer{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.lightBox_imgViewer img{
  object-fit: contain;
  border-radius: 1rem;
}
.imgViewer_button{
  position: absolute;
  z-index: 999;
  color: #ffffff;
  padding: 0.5rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  font-size: 1rem;
}
.prevNext_imgViewer{
  bottom: 50%;
}
.close_imgViewer{
  top: 20px;
  right: 20px;
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .business_wrapper  .content_wrapper{
        border-radius: 0;
        height: 100%;
        width: 100%;
        box-shadow: none;
    }
    .images_container_viewer img{
      width: 4.5rem;
      height: 4.5rem;
    }
 }

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
  
 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    
 }
/* // Tablet & Screens View and above */
 @media (min-width: 768px) {
      
    }
/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {  }

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  }