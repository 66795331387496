.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}
.visible{
  opacity: 1;
  /* display: block; */
}
.hidden{
  opacity: 0;
  /* display: none; */
}
.portfolio__item {
  /* background: var(--color-primary-variant);
  border: 1px solid transparent; */
  background: transparent;
  border: 1px solid var(--color-bg-variant);
  /* padding: 1.3rem; */
  padding: 1.3rem 1.3rem 0;
  border-radius: 2rem;
  transition: var(--transition);
  text-align: center;
}
.portfolio__item:hover {
  background: var(--color-primary-variant);
  /* border-width: 2px; */
} 
.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}
.portfolio__item h4 {
  margin: 1.2rem 0;
}
.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}
/* ======================= PROJECTS FILTERS ======================= */
.projects__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: 2rem !important;
}
.project__type {
  cursor: pointer;
  color: var(--color-white);
  padding: 0.25rem 0.75rem;
  font-weight: 500;
  border-radius: 0.5rem;
  transition: var(--transition);
}
.project__type:hover {
  /* background: rgba(0, 0, 0, 0.3); */
  background: var(--color-primary-variant);
  color: var(--color-white);
  border-color: transparent;
}
.active__type {
  background: var(--color-primary);
  color: var(--color-bg);
}

/* ======================= MEDIA QUERIES (MEDIUM DEVICES) _tablets_ ======================= */
@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}
/* ======================= MEDIA QUERIES (SMALL DEVICES) _mobile_ ======================= */
@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
