a.theme-btn-on-bg,
.theme-btn-on-bg {
  height: 50px;
}

.position-relative {
  position: relative;
}
.progress {
  border-radius: 2px;
}
.section-title {
  font-size: 1.75rem;
}
.skills-cat {
  font-size: 1.25rem;
}
.page-heading {
  font-weight: bold;
  font-size: 2rem;
  color: var(--color-primary);
  margin-bottom: 1rem !important;
}
.header-intro {
  color: #fff !important;
  padding: 3rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  text-align: left !important;
  margin: 0 auto !important;
}

.header-intro-resume {
  height: 320px;
}
.header-intro-resume .container {
  max-width: 1000px;
}
.download-resume {
  right: 15px;
  top: 0;
}
.resume-container {
  width: 100%;
}
.resume-wrapper {
  margin-top: -180px;
  padding-bottom: 100px;
  text-align: center !important;
  position: relative !important;
}
.resume-wrapper-inner {
  max-width: 1000px;
  text-align: left !important;
  margin: 0 auto !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.resume-header {
  /* background: var(--color-bg); */
  border-top: 3px solid white;
  background: var(--color-bg);
  height: 220px;
}
.resume-header .picture {
  width: 220px;
}
.resume-header .name {
  font-size: 2.25rem;
  letter-spacing: 0.175rem;
  font-weight: 900;
  text-transform: uppercase !important;
  margin-bottom: 0.25rem !important;
  margin-top: 0 !important;
}
.resume-header .title {
  font-size: 1.5rem;
  margin-bottom: 1rem !important;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-unstyled li {
  margin-bottom: 0.5rem !important;
}
.text-muted {
  color: var(--color-bg-variant) !important;
}
.resume-header a {
  color: rgba(255, 255, 255, 0.6);
}
.resume-header a:hover {
  color: white;
}
.resume-social {
  font-size: 0.875rem;
}
.resume-social li:not(:last-child) {
  margin-bottom: 0.5rem !important;
}
.resume-social .fa-container {
  font-size: 1rem;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 0.125rem;
  /* background: white; */
  color: var(--color-white);
  text-align: center !important;
  margin-right: 0.5rem !important;
}
.resume-body {
  padding: 3rem !important;
  background-color: #fff !important;
}
.resume-section {
  margin-top: 0 !important;
  margin-bottom: 3rem !important;
}
.resume-section-title {
  font-size: 1.25rem;
  position: relative;
  color: var(--color-primary);
  font-weight: 700 !important;
  text-transform: uppercase !important;
  padding-bottom: 1rem !important;
  margin-bottom: 1rem !important;
  text-align: start !important;
}
.resume-section-title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1.5px;
  background: var(--color-bg-variant);
}
.resume-section-content {
  color: var(--color-white);
}
.resume-section-content > p {
  margin-bottom: 0 !important;
}
.resume-timeline {
  padding-left: 2rem;
}
.resume-timeline:before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 100%;
  background: #c8cfd8;
  left: 6px;
  top: 4px;
  position: absolute;
}
.resume-timeline-item:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -31px;
  top: 0px;
  width: 7px;
  height: 7px;
  border: 3px solid #6f8098;
  background: white;
  border-radius: 50%;
}
.resume-progress {
  height: 0.6rem;
}
.resume-company-name {
  color: #58677c;
  font-size: 0.875rem;
  font-weight: 500;
  margin-left: auto !important;
}
.resume-position-time {
  font-size: 0.875rem;
  color: #aab4c3;
}
.resume-position-title {
  font-size: 1.125rem;
  color: var(--color-primary);
  font-weight: 700 !important;
  margin-bottom: 0.25rem !important;
}
.resume-timeline-item-desc > p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 0.85rem;
}
.resume-timeline-item-desc-heading {
  font-size: 1rem;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.resume-skill-item {
  margin-bottom: 1rem;
}
.resume-skills-cat {
  font-size: 1rem;
  color: var(--color-primary);
}
.resume-skill-name {
  font-size: 0.8125rem;
  color: var(--color-white);
}
.resume-degree {
  color: var(--color-primary);
}
.resume-degree-org {
  font-size: 0.875rem;
}
.resume-degree-time {
  color: #aab4c3;
  font-size: 0.875rem;
}
.resume-award-wrapper {
  padding-left: 1.5rem !important;
}
.resume-award-icon {
  position: absolute;
  left: 0;
  top: 4px;
  color: #aab4c3;
}
.resume-award-name {
  font-weight: bold;
  color: var(--color-primary);
}
.resume-award-desc {
  font-size: 0.875rem;
}
.resume-lang-name {
  color: var(--color-white);
}

.primary-info,
.name,
.primary-info a,
.resume-social a {
  color: var(--color-white);
  text-decoration: none;
}
.primary-info > ul > li {
  margin: 0 !important;
}
.primary-info > ul > li > a,
.resume-social a {
  color: var(--color-light) !important;
  font-size: 0.9rem;
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: var(--color-primary-variant);
  border-radius: 0.25rem;
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--color-bg);
  text-align: center;
  white-space: nowrap;
  background: var(--color-primary);
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.media {
  display: flex;
  align-items: flex-start;
  flex-direction: row !important;
}
.media-body {
  flex: 1 1;
  margin: auto 0 !important;
  padding: 1.5rem !important;
  flex-direction: row;
  display: flex !important;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
article.resume-timeline-item {
  padding-bottom: 3rem !important;
}
.resume-timeline-item-header {
  margin-bottom: 0.5rem !important;
}
.resume-timeline-item-header > div {
  display: flex;
  flex-direction: column;
}
.secondary-info {
  margin-top: 0.5rem !important;
  /* align-items: flex-end;
  justify-content: flex-end;
  display: flex; */
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
.badge-secondary {
  color: #fff;
  background-color: var(--color-bg-variant);
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.col-lg-3,
.col-lg-9 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 992px) {
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 768px) {
  .resume-header {
    height: auto !important;
  }
  .resume-header .picture {
    width: 120px;
    border-radius: 4px;
  }
  .header-intro {
    text-align: center;
  }
  .download-resume {
    position: static !important;
  }
  .header-intro-resume {
    height: auto;
  }
  .resume-wrapper {
    margin-top: 0;
  }
  .resume-timeline-item-header > div {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .secondary-info {
    margin-left: auto !important;
  }
}
@media screen and (max-width: 600px) {
  .resume-container {
    padding-top: 5rem;
  }
  .resume-header {
    height: auto !important;
  }
  .header-intro {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
  }
  .media-body {
    flex-direction: column !important;
  }
  .secondary-info {
    margin-top: 1rem !important;
  }
}
