.services__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  margin-bottom: 3rem!important;
}
.service {
  background: var(--color-primary);
  border-radius: 1.5rem;
  border: 1px solid var(--color-primary);
  height: fit-content;
  transition: var(--transition);
  color: var(--color-bg);
}
.service:hover {
  background: transparent;
  /* border-color: var(--color-primary); */
  cursor: pointer;
  color: var(--color-white);
}
.service__head {
  padding: 4rem 2rem;
  border-radius: 1.5rem;
  box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}
.service__head h3 {
  /* color: var(--color-bg); */
  font-size: 1.2rem;
  text-align: center;
}
.service__button{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 3rem 0 0;
  cursor: pointer;
}
.service__button span{
  margin-right: 0.5rem;
}
.service__button:hover span{
  letter-spacing: 1px;
}
.service__list {
  padding: 2rem;
}
.service__list li {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
}
.service__list-icon {
  color: var(--color-primary);
  margin-top: 2px;
}
.service__list p {
  font-size: 0.9rem;
}
/* ======================= SERVICES CARDS ======================= */
.cards__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 2rem !important;
}
.card__item {
  background: var(--color-bg);
  /* padding: 0.1rem; */
  border-radius: 1.5rem;
  
  transition: var(--transition);
  display: grid;
  place-items: center;
}
.card__item:hover {
  background: transparent;
  border: 1px solid var(--color-bg-variant);
}
/* .card__item:hover > .card__item-image img {
  position: absolute;
  top: -1rem;
} */
.card__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
  /* position: relative; */
}
.card__item h3 {
  margin: 1.2rem 0 2rem;
}
.glassBox {
  width: 100%;
  height: 100%;
  border-radius: 1.5rem;
  background: var(--color-primary-variant);
  backdrop-filter: blur(2px);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  position: relative;
  display: grid;
  place-items: center;
  flex-direction: column;
}
.glassBox,
.glassBox * {
  transition: var(--transition);
}
/* .glassBox__imgBox img {
  display: block;
  width: 100%;
  height: auto;
} */
.glassBox__title {
  text-align: center;
  margin-top: 1rem;
  color: var(--color-white);
  font-size: 1rem;
}
.glassBox:hover {
  background: var(--color-bg);
}
.glassBox:hover .glassBox__imgBox {
  transform: translateY(-2rem);
}
.glassBox:hover .glassBox__imgBox img {
  transform: translate(-1rem, -1.8rem) rotate(-15deg) scale(1.2);
}
.about__services {
  text-align: center;
}
.about__services p {
  margin: 2rem 0 2.6rem;
  color: var(--color-light);
}
/* ======================= SERVICE MODAL ======================= */
.service__modal {
  position: fixed;
  inset: 0;
  background: var(--color-bg-variant-trans);
  padding: 2rem 1rem;
  display: grid;
  place-items: center;
  transition: var(--transition);
  z-index: var(--z-modal);
}
.service__modal-visibility {
  visibility: hidden;
  opacity: 0;
}
.service__modal-content {
  position: relative;
  background: var(--color-bg);
  padding: 4.5rem 1.5rem 2.5rem;
  border-radius: 1.5rem;
}
.service__modal-title,
.service__modal-description {
  text-align: center;
}
.service__modal-title {
  font-size: 2rem;
  color: var(--color-primary);
  margin-bottom: 1rem;
}
.service__modal-description {
  font-size: 0.9rem;
  margin-bottom: 2rem;
  color: var(--color-white);
}
.service__modal-list {
  display: grid;
  row-gap: 0.75rem;
}
.service__modal-item {
  display: flex;
  align-items: flex-start;
  column-gap: 0.5rem;
}
.service__modal-icon {
  font-size: 1.5rem;
  color: var(--color-primary);
}
.service__modal-info {
  font-size: 0.8rem;
}
.service__modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  color: var(--color-primary-variant);
  cursor: pointer;
}
/* ======================= MEDIA QUERIES (MEDIUM DEVICES) _tablets_ ======================= */
@media screen and (max-width: 1024px) {
  .services__container,
  .cards__container {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  .service {
    height: auto;
  }
  .about__services p {
    margin: 1rem 0 1.5rem;
  }
}
/* ======================= MEDIA QUERIES (SMALL DEVICES) _mobile_ ======================= */
@media screen and (max-width: 600px) {
  .services__container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  .cards__container {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .about__services p {
    margin: 1.5rem 0;
  }
}
