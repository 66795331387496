nav {
  background: rgba(255, 255, 255, 0.3);
  width: max-content;
  display: block;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1.5rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(15px);
}
nav button {
  background: transparent;
  padding: 0.9rem;
  border-radius: 50%;
  display: flex;
  color: var(--color-light);
  font-size: 1.1rem;
  cursor: pointer;
  transition: var(--transition);
}

nav button:hover {
  background: var(--color-primary-variant);
}

nav button.active {
  background: linear-gradient(
    180deg,
    var(--color-primary),
    var(--color-primary-variant)
  );
  color: var(--color-bg);
  box-shadow: 0 0 16px var(--color-bg-variant);
}
