/**
 * Mobster v1.0.0
 * Copyright 2020 by MACode ID
 * Site: www.macodeid.com
 * License under CC 4.0 ()
 */

hr {
  border-color: #e4e1eb;
}

.no-transform {
  -webkit-transform: none !important;
  transform: none !important;
}

.no-scroll {
  overflow: hidden;
}

.no-scroll-x {
  overflow-x: hidden;
}
.cardTxt {
  color: var(--color-light);
}
.fs-small {
  font-size: 14px !important;
}
.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}
.bg-success {
  background-color: #28a745 !important;
}
.bg-warning {
  background-color: #e89e09 !important;
}

.bg-info {
  background-color: #4699f8 !important;
}

.bg-danger {
  background-color: #f13057 !important;
}

.bg-indigo {
  background-color: #602fda !important;
}

.bg-light {
  background-color: #f0eef5 !important;
}

.fg-primary {
  color: #3d58f3 !important;
}

.fg-gray {
  color: #6b6872 !important;
}

.fg-white {
  color: #fff !important;
}

/* Shadow */
.shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(56, 51, 64, 0.115) !important;
}

/* Image */
.bg-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.img-place {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
}

.img-place > img {
  width: 100%;
  height: 100%;
}

.img-place.w-auto > img {
  width: auto;
  max-width: 100%;
}

.img-place.h-auto > img {
  height: auto;
  max-height: 100%;
}

.img-place.custom-size > img {
  width: unset;
  height: unset;
}

.iconic-md {
  display: flex;
  margin: 0;
  padding: 0;
  width: 70px;
  height: 70px;
  overflow: hidden;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.iconic-md.iconic-text {
  line-height: 70px;
  border-radius: 50%;
  font-size: 1.5rem;
}

.iconic-md.iconic-text > span {
  font-size: 32px;
  line-height: inherit;
}

.iconic-sm img,
.iconic-md img,
.iconic-lg img {
  width: 100%;
  height: 100%;
}

.iconic-list {
  position: relative;
  list-style: none;
  padding-left: 0;
}

.iconic-item {
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  padding: 12px 0;
}

.iconic-item .iconic-content {
  margin: 0 1rem;
  flex-basis: calc(100% - 100px);
}

/* List */
.theme-list {
  position: relative;
  padding-left: 16px;
  list-style: none;
}

.theme-list .list-item {
  padding-top: 6px;
  padding-bottom: 6px;
}

.theme-list .list-item::before {
  content: "";
  display: inline-block;
  margin-right: 8px;
  width: 20px;
  height: 20px;
  background-color: rgba(96, 47, 218, 0.16);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.568' height='7.818' viewBox='0 0 11.568 7.818'%3E%3Cpath id='Path_56' data-name='Path 56' d='M573,3671.99l4.2,2.76,5.55-6.25' transform='translate(-572.1 -3667.582)' fill='none' stroke='%23602fda' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.3'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  transform: translateY(4px);
}

/* Accordion */
.accordion {
  position: relative;
  display: block;
  width: 100%;
}

.accordion-item {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.accordion-trigger {
  width: 100%;
  overflow: hidden;
}

.accordion-item:first-child .accordion-trigger {
  border-radius: 4px 4px 0 0;
}

.accordion-item:last-child .accordion-trigger {
  border-radius: 0 0 4px 4px;
}

.accordion-trigger .btn {
  display: block;
  width: 100%;
  margin: 0;
  padding: 12px 16px;
  height: unset;
  text-align: left;
  background-color: #fff;
  color: #6b6872;
  border-radius: 0;
}

.accordion-trigger .btn:focus {
  box-shadow: none;
}

.accordion-item .btn:not(.collapsed) {
  background-color: #3d58f3;
  color: #fff;
}

.accordion-trigger .btn::after {
  content: "";
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%235F6677' d='M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z'/%3E%3C/svg%3E");
  background-size: 75% 75%;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.3s ease;
}

.accordion-item .btn:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z'/%3E%3C/svg%3E");
  transform: rotate(-180deg);
}

.accordion-content {
  padding: 16px;
  background-color: #fff;
}

.accordion-gap .accordion-item:not(:last-child) {
  margin-bottom: 10px;
}

.accordion-gap .accordion-item:first-child .accordion-trigger,
.accordion-gap .accordion-item:last-child .accordion-trigger {
  border-radius: 0;
}

.accordion-gap .accordion-trigger .btn {
  border-radius: 4px;
}

/* Buttons */
.btn {
  padding: 10px 24px;
  border-width: 2px;
}

.btn-secondary:hover,
.btn-primary:hover {
  box-shadow: 0 4px 12px rgba(61, 88, 243, 0.3);
}

.btn-primary:focus {
  box-shadow: none;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #ffffff;
  background-color: #556df4;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #3d58f3;
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-dark {
  color: #ffffff;
  background-color: #383340;
  border-color: transparent !important;
}

.btn-dark:hover {
  color: #ffffff;
  background-color: #242129;
  box-shadow: 0 4px 12px rgba(56, 51, 64, 0.3);
}

.btn-dark:focus {
  color: #ffffff;
  background-color: #242129;
  box-shadow: none;
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #ffffff;
  background-color: #443e4e;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #383340;
  box-shadow: none;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: none;
}

[class*="btn-outline-"] {
  border-width: 2px;
  font-weight: 500;
}

.btn-outline-primary {
  color: #3d58f3;
  border-color: #3d58f3;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #3d58f3;
  border-color: #3d58f3;
  box-shadow: 0 4px 12px rgba(61, 88, 243, 0.3);
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: none;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #3d58f3;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #3d58f3;
  border-color: #3d58f3;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-group.rounded-pill > .btn {
  border-radius: 50rem;
}
.btn-group.rounded-pill > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.page-hero-section {
  position: relative;
  height: 100vh;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  background-image: url(../../img/bg_hero_1.svg);
}
.page-hero-section h1 {
  font-size: 2.5rem;
  line-height: 3rem;
}
.page-hero-section .hero-caption {
  height: 100%;
  padding-top: 3rem !important;
}

.page-section {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}

.client-img > img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100px;
}

.floating-animate {
  animation: bounceLimit 4s linear infinite;
}

@keyframes bounceLimit {
  0%,
  50%,
  100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(4px);
  }
  75% {
    transform: translateY(-4px);
  }
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.card-body h4 {
  color: var(--color-white);
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 1.5rem;
  transition: var(--transition);
  background: var(--color-bg-variant);
  border: 1px solid var(--color-bg-variant);
}
.card:hover {
  background: transparent;
  border-color: var(--color-primary);
  cursor: default;
}
.iconic-content p,
.info__card p {
  color: var(--color-light);
}
.mt-5 {
  margin-top: 3rem !important;
}
.text-center {
  text-align: center !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-center {
  align-items: center !important;
}
.justify-content-center {
  justify-content: center !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.pb-3, .py-3 {
  padding-bottom: 1rem !important;
}
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .pricing-table {
    flex-wrap: nowrap;
  }
  .pricing-item.active {
    transform: translateY(-50px);
  }
}

.mobile-preview {
  width: 100%;
  max-width: 233px;
  height: auto;
  border-radius: 30px;
}

.hero-home-1 .mobile-preview {
  margin-top: 20%;
}

.hero-home-1 .badge {
  padding: 4px 0;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0.5rem !important;
}

.hero-home-1 .badge .icon {
  display: inline-block;
  padding: 0;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  background-color: #9548f9;
  color: #fff;
  font-size: 12px;
}

.hero-home-2 {
  min-height: 780px;
}

.hero-home-2 .mobile-preview {
  max-width: 250px;
  border-radius: 40px;
  margin-top: 20%;
}

.hero-home-2 .badge-soft {
  padding: 8px 16px;
  border-radius: 50rem;
  background-color: rgba(255, 255, 255, 0.175);
  font-weight: 500;
}

.hero-mini {
  height: 462px;
}

/* Pricing Table */

.pricing-table {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.pricing-item {
  display: inline-block;
  margin: 15px;
  padding: 60px 20px 80px 20px;
  background-color: var(--color-white);
  background-image: url(../../img/pricing_pattern_grayscale.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 300px;
  border-radius: 8px;
  color: var(--color-bg);
  box-shadow: 0 4px 12px rgba(56, 51, 64, 0.185);
}

.pricing-item .theme-list {
  padding-left: 0;
}

.pricing-body {
  padding-top: 32px;
  padding-bottom: 32px;
}

.pricing-item.active {
  background-image: url(../../img/pricing_pattern.svg);
}

.active .pricing-header h1 {
  color: #602fda;
}

.pricing-item:not(.active) .theme-list .list-item::before {
  background-color: rgba(95, 90, 104, 0.16);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.568' height='7.818' viewBox='0 0 11.568 7.818'%3E%3Cpath id='Path_56' data-name='Path 56' d='M573,3671.99l4.2,2.76,5.55-6.25' transform='translate(-572.1 -3667.582)' fill='none' stroke='%235F5A68' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.3'/%3E%3C/svg%3E");
}

.btn-switch .btn {
  background-color: transparent;
  color: #b2b4bf;
  border: 2px solid #f0eef5;
  font-weight: 500;
}

.btn-switch .btn.active {
  background-color: #3d58f3;
  color: #fff;
}

.btn-switch .btn:focus,
.btn-switch .btn.focus {
  box-shadow: none;
}

.my-collapse {
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}

.faq_card:hover {
  border-color: var(--color-primary);
  cursor: pointer;
}
.faq_card{
  width: 100%;
  cursor: pointer !important;
  margin-bottom: 0.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 1.1rem;
  transition: var(--transition);
  background: transparent;
  border: 1px solid var(--color-bg-variant);
}
.faq__card-header{
  padding: 1rem;
  border-radius: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: var(--color-bg-variant);
  transition: var(--transition);

}
.open_faq{
  border-radius: 1.1rem 1.1rem 0 0 !important;
  background: var(--color-primary) !important;
}

.faq_button{
  background: none;
  color: var(--color-primary);
}
.faq_button-open{
  background: none;
  color: var(--color-white);
}
.faq__content{
  padding: 1rem;
  font-size: 0.8rem;
  border-radius: 1.5rem;

  background: var(--color-bg);
  color: var(--color-light);
}
@media screen and (max-width: 600px) {
  .mobile-preview {
    max-width: 160px;
  }
  .hero-home-1 .mobile-preview {
    margin-top: 10%;
}
}